 /* Yeh class tumhare home page ke liye hai */

     

 .imageB {
    overflow: hidden;
}
 
.slider-container {
    width: 80%; /* Adjust as needed */
    overflow: hidden;
}

.moving_animationn {
    animation: slideRightToLeft 10s linear infinite; /* Adjust animation duration and timing function as needed */
}

@keyframes slideRightToLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

/* App.css */
.step_block {
  margin-top: 20px;
}

.step_text {
  padding: 10px;
}

.step_number h3 {
  font-size: 2rem;
  margin: 0;
}

.step_img img {
  max-width: 100%;
  height: auto;
}

  .navbar-links {
    background-color: white;
    color: black;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-links ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .navbar-links li {
    margin-bottom: 10px;
    list-style-type: none;
  }
  
  .navbar-links a {
    color: black;
    text-decoration: none;
    font-weight: bold;
    display: block;
    padding: 10px;

    border-radius: 4px;
  }
  
  .navbar-links a:hover {
    background-color: #e0e0e0;
  }
  
  .search-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .search-container input {
    flex-grow: 1;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .search-container i {
    margin-left: 10px;
    cursor: pointer;
  }
  
  .ion-load-c {
    display: block;
    margin: 20px auto;
    font-size: 24px;
  }
  
  @media (max-width: 600px) {
    .navbar-links a {
      font-size: 14px;
      padding: 8px;
    }
    .search-container input {
      width: 100%;
      padding: 8px;
    }
    .ion-load-c {
      font-size: 20px;
    }
  }
  

  .bg-hero{
    background-image: linear-gradient(to right, rgba(5, 5, 5, 0.068), #f2b40bd7);
  }
  .firstGradient{
    background: radial-gradient(circle at left center, rgba(242, 180, 11, 0.5) 100px, #fff 500px);
  }
  .secondGradient{
    background: radial-gradient(circle at right center, rgba(242, 180, 11, 0.5) 80px, #fff 400px);
  }
  .thirdGradient{
    background: radial-gradient(circle at left center, rgba(242, 180, 11, 0.5) 10px, #fff 300px);
  }
  .free_app_inner{
    /* background-image: linear-gradient(to right, rgba(248, 242, 149, 0.678), #f3ba1ed7) !important; */
    padding: 50px !important;
    background: linear-gradient(130deg, rgba(242, 180, 11, 0.342) 0%, rgba(242, 180, 11, 0.952) 35%, rgba(242, 180, 11, 0.452) 100%); 
  }
  .newsletter_box{
    background-image: linear-gradient(to right, rgba(134, 127, 127, 0.856), #f2b40bd7 ,  #16130ad7) !important;
    padding: 5px !important;
    text-align: center !important;
    }
  
  .fb{
    background-color: #f2b40b !important;
  }

  .home{
    color: black ;
  }
  .home:hover{
    color: #f2b40b;
  }

  @media screen and (max-width: 768px) {
    .yt_video {
      height: 270px;
    }
  
    .play-button img {
      width: 50px;
      height: 50px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .yt_video {
      height: 200px;
    }
  
    .play-button img {
      width: 40px;
      height: 40px;
    }
  }

  .free_app_section{
    padding: 0px !important;
  }
  
  
