.nav-btn{
    background-color: #f2b40b;
    border: none;
    padding: 10px 15px;
    font-weight: 600;
    border-radius: 20px;
}
.nav-link{
    color: black;
    font-size: 18px !important;
}

.home{
    color: black !important ;
  }
  .home:hover{
    color: #f2b40b !important;
  }

  .navbar-toggler-icon{
    margin-left: 120px !important;
  }
