.whatsapp-icon {
    position: fixed;
    top: 75vh;
    right: 20px;
    z-index: 1000;
    font-size: 50px;
    color: #25d366;
    border-radius: 50%;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .whatsapp-icon:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
    transform: scale(1.1);
  }
  